import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';
import { graphql, useStaticQuery } from "gatsby";
import "./MenuList.module.scss";
import "./MenuTabs.scss";
import One from "../../images/menu/1.jpg"
// import Two from "../../images/menu/2.jpg"
import Four from "../../images/menu.jpg"
import DavisOne from "../../images/menu/sitlo-menu.jpeg"
import DavisTwo from "../../images/menu/sitlodrinks.jpeg"

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

function TabPanel(props) {
    const { children, value, index } = props
    return <div>{value === index && <div style={{ margin: '0 auto' }}>{children}</div>}</div>
}


export default function MenuTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const data = useStaticQuery(graphql`
        query {    
            allContentfulMenu (sort: { fields: [menuImage___title], order: ASC }) {
                    edges {
                        node {
                            menuImage {
                                title
                                file {
                                    url
                                }
                            }
                        }
                    }
            }
        }
    `)

    const [menuDishes, menuDrinks, menuSteps] = data.allContentfulMenu.edges[0].node.menuImage.map(img => img.file.url)

    return (
        <div className="menu__page">
            <div className="tabs">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Elk Grove" disableRipple />
                    <Tab label="Davis" disableRipple />
                    {/* <Tab label="Drinks" disableRipple /> */}
                </Tabs>
            </div>
            <div className="menu__imageContainer">
                <TabPanel value={value} index={0}>
                    <div className="menu__image">
                        {/* <img src={One} /> */}
                        {/* <img className="image" src={Two} /> */}
                        <img className="image" src={DavisOne} />
                        <img className="image" src={DavisTwo} />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1} >
                    <div className="menu__image">
                        <img className="image" src={DavisOne} />
                        <img className="image" src={DavisTwo} />
                    </div>
                </TabPanel>

                {/* <TabPanel value={value} index={2}>
                    <div className="menu__image">
                        <img src={Three} />
                    </div>
                </TabPanel> */}
            </div>
        </div>
    );
}
