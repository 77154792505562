import React from "react"
import Layout from "../components/layout"
import ParallaxCard from "../components/ParallaxCard"
import Food from "../images/hero/pho.jpg"
import Header from "../components/header"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import MenuTabs from "../components/MenuTabs/MenuTabs"
// import One from "../images/menu/1.jpg"
// import Two from "../images/menu/2.jpg"
// import Three from "../images/menu/3.jpg"

const MenuPage = () => {
  return (
    <>
      <GatsbySeo
        title="SitLo Saigon Menu"
        description="Our menu has the best pho in Elk Grove, best pho in Sacramento county, Vietnamese food, pho, noodles, broth and meat."
        canonical="/menu"
      />
      <Layout>
        <Header />
        <ParallaxCard
          height="50vh"
          image={Food}
          alt="Pho"
          children={<h1>Menu</h1>}
        />
        <MenuTabs />
        {/* <div className="menu">
        <img src={One} alt="menu 1" />
        <img src={Two} alt="menu 2" />
        <img src={Three} alt="menu 3" />
      </div> */}
      </Layout>
    </>
  )
}

export default MenuPage

/*********  If you want to redirect title to another page with info *******************
          <li>
              <Link to={`/menu/${edge.node.fields.slug}`}>
                <h4>{edge.node.frontmatter.title}</h4>
                <p>{edge.node.frontmatter.date}</p>
              </Link>
            </li>
          
          
          */
